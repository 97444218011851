import {
    Admin,
    Resource,
    localStorageStore,
    useStore,
    StoreContextProvider,
} from 'react-admin';
import { Route } from 'react-router';

import authProvider from './authProvider';
import dataProvider from './dataProvider';
import { Layout, Login } from './layout';
import blogctrbitems from './blogctrbitems';
import blogitems from './blogitems';
import blogtags from './blogtags';
import mcampaigns from './mcampaigns';
import { themes, ThemeName } from './themes/themes';
import MCampaignLogList from "./mcampaignlogs/MCampaignLogList";
import MCampaignLogCreate from "./mcampaignlogs/MCampaignLogCreate";
import MCampaignLogEdit from "./mcampaignlogs/MCampaignLogEdit";

const store = localStorageStore(undefined, 'ECommerce');

const App = () => {
    const [themeName] = useStore<ThemeName>('themeName', 'soft');
    const lightTheme = themes.find(theme => theme.name === themeName)?.light;
    const darkTheme = themes.find(theme => theme.name === themeName)?.dark;
    return (
        <Admin
            title={'捷可印-后台管理系统'}
            dataProvider={dataProvider}
            store={store}
            authProvider={authProvider}
            loginPage={Login}
            layout={Layout}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            defaultTheme="light"
        >
            <Resource name="blogctrbitems" {...blogctrbitems} />
            <Resource name="blogitems" {...blogitems} />
            <Resource name="blogtags" {...blogtags} />
            <Resource name="mcampaigns" {...mcampaigns}>
                <Route path=":id/mcampaignlogs" element={<MCampaignLogList/>} />
                <Route path=":id/mcampaignlogs/create" element={<MCampaignLogCreate/>} />
                <Route path=":id/mcampaignlogs/:logId" element={<MCampaignLogEdit/>} />
            </Resource>
        </Admin>
    );
};

const AppWrapper = () => (
    <StoreContextProvider value={store}>
        <App />
    </StoreContextProvider>
);

export default AppWrapper;
